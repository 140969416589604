const TEST_PRICES = {
  'enclomiphene': {
    'yearly': {
      'testosterone_pricing_variation_0': 99.99,
      'testosterone_pricing_variation_1': 99.99,
      'testosterone_pricing_variation_2': 79.99
    },
    'quarterly': {
      'testosterone_pricing_variation_0': 149.99,
      'testosterone_pricing_variation_1': 124.99,
      'testosterone_pricing_variation_2': 119.99
    },
    'monthly': {
      'testosterone_pricing_variation_0': 199.99,
      'testosterone_pricing_variation_1': 149.99,
      'testosterone_pricing_variation_2': 149.99
    }
  },
  'oral-trt': {
    'yearly': {
      'testosterone_pricing_variation_0': 149.99,
      'testosterone_pricing_variation_1': 149.99,
      'testosterone_pricing_variation_2': 129.99
    },
    'quarterly': {
      'testosterone_pricing_variation_0': 199.99,
      'testosterone_pricing_variation_1': 174.99,
      'testosterone_pricing_variation_2': 169.99
    },
    'monthly': {
      'testosterone_pricing_variation_0': 249.99,
      'testosterone_pricing_variation_1': 199.99,
      'testosterone_pricing_variation_2': 199.99
    }
  },
  'trt-gel': {
    'yearly': {
      'testosterone_pricing_variation_0': 109.99,
      'testosterone_pricing_variation_1': 109.99,
      'testosterone_pricing_variation_2': 89.99
    },
    'quarterly': {
      'testosterone_pricing_variation_0': 159.99,
      'testosterone_pricing_variation_1': 134.99,
      'testosterone_pricing_variation_2': 129.99
    },
    'monthly': {
      'testosterone_pricing_variation_0': 209.99,
      'testosterone_pricing_variation_1': 159.99,
      'testosterone_pricing_variation_2': 159.99
    }
  },
  'injectable-trt': {
    'yearly': {
      'testosterone_pricing_variation_0': 99.99,
      'testosterone_pricing_variation_1': 99.99,
      'testosterone_pricing_variation_2': 79.99
    },
    'quarterly': {
      'testosterone_pricing_variation_0': 149.99,
      'testosterone_pricing_variation_1': 124.99,
      'testosterone_pricing_variation_2': 119.99
    },
    'monthly': {
      'testosterone_pricing_variation_0': 199.99,
      'testosterone_pricing_variation_1': 149.99,
      'testosterone_pricing_variation_2': 149.99
    }
  },
  'oral-trt-+-enclomiphene': {
    'yearly': {
      'testosterone_pricing_variation_0': 199.99,
      'testosterone_pricing_variation_1': 199.99,
      'testosterone_pricing_variation_2': 179.99
    },
    'quarterly': {
      'testosterone_pricing_variation_0': 249.99,
      'testosterone_pricing_variation_1': 224.99,
      'testosterone_pricing_variation_2': 219.99
    },
    'monthly': {
      'testosterone_pricing_variation_0': 299.99,
      'testosterone_pricing_variation_1': 249.99,
      'testosterone_pricing_variation_2': 249.99
    }
  },
  'trt-gel-+-enclomiphene': {
    'yearly': {
      'testosterone_pricing_variation_0': 189.99,
      'testosterone_pricing_variation_1': 189.99,
      'testosterone_pricing_variation_2': 169.99
    },
    'quarterly': {
      'testosterone_pricing_variation_0': 239.99,
      'testosterone_pricing_variation_1': 214.99,
      'testosterone_pricing_variation_2': 209.99
    },
    'monthly': {
      'testosterone_pricing_variation_0': 289.99,
      'testosterone_pricing_variation_1': 239.99,
      'testosterone_pricing_variation_2': 239.99
    }
  }
}

export const existPriceExperiment = (product, period, variant) => {
  if (TEST_PRICES[product]?.[period]?.[variant]) {
    return true
  } else {
    return false
  }
}

export const getPriceExperiment = (product, period, variant, defaultPrice, integerOnly = false) => {
  let price = defaultPrice

  if (TEST_PRICES[product]?.[period]?.[variant]) {
    price = `$${TEST_PRICES[product][period][variant]}`
  }

  if (integerOnly) {
    return price.replace('.99', '')
  } else {
    return price
  }
}